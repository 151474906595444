import type { RouteProps } from 'react-router-dom'
import type { PageContext, TemplateName } from '@which/glide-ts-types'

import { aboutWhichRoutes } from './about-us/about-us.routes'
import { accountRoutes } from './account/account.routes'
import { articlePageRoutes } from './article'
import { campaignsRoutes } from './campaigns/campaigns.routes'
import { consumerRightsRoutes } from './consumer-rights/consumer-rights.routes'
import { energyRoutes } from './energy/energy.routes'
import { homeRoutes } from './home/home.routes'
import { hubsRoutes } from './hubs/hubs.routes'
import { liveReportsRoutes } from './live-reports/live-reports.routes'
import { marketingHubRoutes } from './marketing-hub/marketing-hub.routes'
import { moneyRoutes } from './money/money.routes'
import { myAppointmentsRoutes } from './my-appointments/my-appointments.routes'
import { newsRoutes } from './news/news.routes'
import { pensionSchemeRoutes } from './pension-scheme/pension-scheme.routes'
import { policyAndInsightRoutes } from './policy-and-insight/policy-and-insight.routes'
import { reviewRoutes } from './reviews/reviews.routes'
import { savedRoutes } from './saved/saved.routes'
import { signupCompleteRoutes } from './signup-complete/signup-complete.routes'
import { toolFrameworkRoutes } from './tool/tool.routes'

export const routes: WCDRouteProps = [
  ...aboutWhichRoutes,
  ...accountRoutes,
  ...articlePageRoutes,
  ...campaignsRoutes,
  ...consumerRightsRoutes,
  ...energyRoutes,
  ...homeRoutes,
  ...hubsRoutes,
  ...liveReportsRoutes,
  ...marketingHubRoutes,
  ...moneyRoutes,
  ...myAppointmentsRoutes,
  ...newsRoutes,
  ...pensionSchemeRoutes,
  ...policyAndInsightRoutes,
  ...reviewRoutes,
  ...savedRoutes,
  ...signupCompleteRoutes,
  ...toolFrameworkRoutes,
]

///////// IMPLEMENTATION /////////

type PagePath =
  | 'article-listing/ArticleListingPage'
  | 'article/AboutWhichArticlePage'
  | 'article/ArticlePage'
  | 'article/PensionArticlePage'
  | 'article/PIArticlePage'
  | 'article/ReviewsAdviceSinglePage'
  | 'article/ReviewsAdviceMultiPage'
  | 'article/NewsArticlePage'
  | 'article/ConsumerRightsPage'
  | 'article/ConsumeRightsCarAdviceToolPage'
  | 'author/AuthorPage'
  | 'cr-article-type/ArticleTypePage'
  | 'energy/EnergyPage'
  | 'home/HomePage'
  | 'live-report/LiveReportPage'
  | 'marketing-hub/MarketingHubPage'
  | 'product-hub/ProductHubPage'
  | 'reviews/product-comparison/ProductComparisonPage'
  | 'reviews/product-listing/ProductListingPage'
  | 'reviews/product-page/ProductPage'
  | 'saved/SavedPage'
  | 'search-results/SearchResultsPage'
  | 'signup-complete/SignupCompletePage'
  | 'sub-vertical/SubVerticalPage'
  | 'tool-framework/ToolFrameworkPage'
  | 'vertical/VerticalPage'

export type PageParams = {
  verticalSlug: string
  subVerticalSlug: string
  categorySlug: string
  superCategorySlug: string
  collectionSlug: string
  articleName: string
  articleSlug: string
  articleType: 'advice' | 'letter' | 'regulation'
  articleTypeSlug: 'letters' | 'regulations'
  toolSlug: 'used-car'
  toolPage: string
  filterSlug: string
  filterValue: string
  searchResultsSlug: string
  searchResultsPage: string
  authorSlug: string
  authorPage: string
  productSlug: string
  productHubSlug: string
  liveReportSlug: string
  taxonomySlug: string
}

export type ArticleType = PageParams['articleType']

export type PageProps = {
  pagePath: PagePath
  template?: TemplateName
  rootCollectionName?: string
  context?: PageContext
}

export type WCDRouteProps = (RouteProps & {
  live: boolean
  prod?: boolean
  preprod?: boolean
})[]
